import { includes, negate } from 'lodash'

const envVariables = {
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  APP_NAME: import.meta.env.VITE_APP_NAME,
  ENVIRONMENT: import.meta.env.VITE_ENV,
  INCODE_API_KEY: import.meta.env.VITE_INCODE_API_KEY,
  INCODE_ONBOARDING_FLOW_ID: import.meta.env.VITE_INCODE_ONBOARDING_FLOW_ID,
  INCODE_DOCUMENT_UPLOAD_FLOW_ID: import.meta.env
    .VITE_INCODE_DOCUMENT_UPLOAD_FLOW_ID,
  INCODE_API_URL: import.meta.env.VITE_INCODE_API_URL,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  GCP_API_KEY: import.meta.env.VITE_GCP_API_KEY,

  // Starting from here are optional env variables for local development
  DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || '',

  ONE_DRIVE_CLIENT_ID: import.meta.env.VITE_ONE_DRIVE_CLIENT_ID,
  ONE_DRIVE_AUTHORITY_URL: import.meta.env.VITE_ONE_DRIVE_AUTHORITY_URL,
  GOOGLE_DRIVE_CLIENT_ID: import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID,
  ALCHEMY_URL: import.meta.env.VITE_ALCHEMY_URL,
  POLYGON_ASSET_VDT_CONTRACT_ADDRESS: import.meta.env
    .VITE_POLYGON_ASSET_VDT_CONTRACT_ADDRESS,
}

const optionalForLocalDevelopment = ['DATADOG_CLIENT_TOKEN', 'SENTRY_DSN']

Object.entries(envVariables).forEach(([key, value]) => {
  if (
    value === undefined &&
    negate(includes)(optionalForLocalDevelopment, key)
  ) {
    throw new Error(`Missing environment variable ${key}`)
  }
})

export default envVariables
